<template>
  <lab-task
    ><lab-calorimetry
      :variation="variation"
      :thermometer-variation="thermometerVariation"
      :c-cal="cCal"
      :temp-before-mixing="tempBeforeMixing"
    />
  </lab-task>
</template>

<script>
import Vue from 'vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {
  default as LabCalorimetry,
  ThermometerVariation,
  SchoolToVariation,
} from '@/tasks/components/simulations/LabCalorimetry';
import LabTask from './labs/LabTask';

export default Vue.extend({
  name: 'UPEICalorimetry',
  components: {LabCalorimetry, LabTask},
  mixins: [DynamicQuestionMixin()],
  data: () => ({
    variation: SchoolToVariation.Upei,
    thermometerVariation: ThermometerVariation.Analog,
  }),
  computed: {
    cCal() {
      return this.taskState.getValueBySymbol('cCal').content.toFloat();
    },
    tempBeforeMixing() {
      return this.taskState.getValueBySymbol('Tbm').content.toFloat();
    },
  },
});
</script>
